import {useTranslation} from "react-i18next";
import "./Header.css"

const HeaderDropDown = () => {
    const { i18n} = useTranslation();
    const languages = [
        {
            name: 'English',
            code: 'en',
            nativeName: 'English',
        },
        {
            name: 'Türkçe',
            code: 'tr',
            nativeName: 'Türkçe',
        },
        {
            name: 'Deutsch',
            code: 'de',
            nativeName: 'Deutsch',
        },
        {
            name: 'Italiano',
            code: 'it',
            nativeName: 'Italiano',
        },
        {
            name: 'Français',
            code: 'fr',
            nativeName: 'Français',
        },
        {
            name: 'Português',
            code: 'pt',
            nativeName: 'Português',
        },
        {
            name: 'Español',
            code: 'es',
            nativeName: 'Español',
        },
        {
            name: 'Nederlands',
            code: 'nl',
            nativeName: 'Nederlands',
        },
        {
            name: 'Indonesia',
            code: 'id',
            nativeName: 'Indonesia',
        },
        {
            name: 'Русский',
            code: 'ru',
            nativeName: 'Русский',
        },
        {
            name: 'हिंदी',
            code: 'hi',
            nativeName: 'हिंदी',
        },
        {
            name: 'عربي',
            code: 'ar',
            nativeName: 'عربي',
        },
        {
            name: 'עִברִית',
            code: 'he',
            nativeName: 'עִברִית',
        },
    ]

    return (
        <div>
            <button className="peer">
                {
                    <img src={`/langIcons/${i18n.language.toLocaleUpperCase() === 'EN-US'?'en':i18n.language && i18n.language.toLocaleUpperCase() === 'TR-TR' ? 'tr' : i18n.language}.png`} alt={`${i18n.language}.png`} className={"bayrak"}/>
                }
                <p className="bayrak-p">
                    {i18n.language.toLocaleUpperCase() === 'EN-US'?'EN':i18n.language.toLocaleUpperCase() && i18n.language.toLocaleUpperCase() === 'TR-TR' ? 'TR' : i18n.language.toLocaleUpperCase()}
                </p>
            </button>
            <details className="drop-down-container">
                <summary className="drop-down">
                    {
                        languages.map(lang =>
                            <li onClick={() => i18n.changeLanguage(lang.code)} key={lang}
                                                  className="drop-down-content">
                            <img src={`/langIcons/${lang.code}.png`} alt={`${lang.code}.png`}
                                 className="bayrak border-circle"/>
                            <p className="bayrak-p">
                                {lang.nativeName}
                            </p>
                        </li>)
                    }
                </summary>
            </details>
        </div>
    );
}

export default HeaderDropDown
