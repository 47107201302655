import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";


i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "tr", resources: {
            en: {
                translation: {
                    card: [
                        {
                            title: "Tracking works successfully.",
                            content: "We know this is annoying, but due to WhatsApp's latest privacy update, it is no longer possible to follow people you haven't interacted with (texting and voice calls) in the last 3 weeks. We would like to remind you that no WhatsApp tracking application in the world is working anymore. However, by connecting your QR code to our system, the people with whom you have chatted or made a voice call will continue to be actively followed.",
                        },
                        {
                            title: "How Can I Connect My WhatsApp To The System?",
                            content: "You need to scan the QR code we specified on the screen by entering the WhatsApp application and clicking on the 'connect your device' option.\n" +
                                "You will need 2 devices to do this. You need to view the QR code from a device and read the QR code from your own phone. You can see all the necessary steps and instructions in the video at the top of the page.",
                        }, {
                            title: "I Don't Want To Connect My WhatsApp To The System!",
                            content: "We understand your concern, but you must do this for Tracking to begin. If you do not make your WhatsApp Connection, the tracking process will only start for WhatsApp Business numbers.",
                        }, {
                            title: "What Will Happen To My Privacy When I Connect The QR Code To The System?",
                            content: "In the process of connecting the QR code to the system, we will only receive the data required to start the tracking. Your messages, media and chat history will never be stored on our servers.",
                        }, {
                            title: "Will I Always Appear 'Online' When I Scan The QR Code?",
                            content: "Absolutely not, your session will be taken into 'offline' status by us after the data required for QR code scanning is received.",
                        }, {
                            title: "Does The Person I Follow Need To Connect The QR Code To The System?",
                            content: "No, you just need to connect your QR code to the system. The number you track does not need to take any action.",
                        }, {
                            title: "The Online Information Of The Person I Will Follow Is Closed, Can I Follow Still?",
                            content: "Yes, your follow-up process will be done without any problems, regardless of the last seen or online status of the person you follow.",
                        }, {
                            title: "Will The Person I Follow Be Able To See That They Are Being Followed?",
                            content: "No, you are completely anonymous from tracking transactions. The number you tracked will never know that it is being tracked.",
                        }, {
                            title: "Can I Follow The Person Whose Phone Number I Blocked Or Blocked?",
                            content: "Our technical team continues to work on this issue. As soon as possible, we will publish an update to track the numbers you have blocked or blocked.",
                        }, {
                            title: "Does My WhatsApp Have To Be Constantly Connected To The WanLog System?",
                            content: "No, you can disconnect after connecting your WhatsApp application to the system. However, we recommend that you do not log out of the connection in order to keep the tracking process stable.",
                        },
                        {
                            title: "What Do The Signs Next To People I Follow Mean?",
                            content: "Tracking works successfully.",
                            content2: "Waiting for the person you follow to send you a message.",
                            content3: "You need to connect the QR code to the system.",
                            content4: "Getting ready to follow.",
                        }
                    ],
                    share: {
                        title: "Send to Someone You Can Reach",
                        close: "Close",
                    },
                    qr: {
                        shareButton: "Share",
                        countDownFirst: "QR code will refresh in",
                        countDownSecon: "seconds.",
                        waiting: "Waiting for Connection",
                        connected: "Connected",
                        opinion1: "Open WhatsApp on your phone",
                        opinion2: "Tap on Settings Select Connected Devices",
                        opinion3: "Tap on Connect Device",
                        opinion4: "Scan the code by pointing your phone at this screen",
                    },
                    link:"https://www.youtube.com/embed/Y_9LMtWZcyQ?si=K-insrk4Y6KzH1tI"
                },
            },
            tr: {
                translation: {
                    card: [
                        {
                            title: "Takip başarıyla çalışıyor.",
                            content: "Bu durumun can sıkıcı olduğunu biliyoruz fakat WhatsApp’ın son yapmış olduğu gizlilik güncellemesinden dolayı son 3 hafta içerisinde etkileşimde olmadığınız (mesajlaşma ve sesli görüşme) kişileri takip etmek artık mümkün değil. Dünyada ki hiçbir WhatsApp takip uygulaması artık çalışmadığını tekrar hatırlatmak isteriz. Fakat QR kodunuzu sistemimize bağlayarak daha önce sohbet ettiğiniz ya da sesli görüşme yaptığınız kişilerin takipleri aktif bir şekilde devam edecektir.",
                        },
                        {
                            title: "WhatsApp'ımı Sisteme Nasıl Bağlayabilirim?",
                            content: "Ekranda belirttiğimiz QR kodunu WhatsApp uygulamasına girip 'cihazınızı bağlayın' seçeneğine tıklayarak okutmanız gerekmektedir.\n" +
                                "Bu işlemi yapmanız için 2 adet cihaza ihtiyacınız olacaktır. Bir cihazdan QR kodunu görüntüleyip kendi telefonunuzdan QR kodunu okutmanız gerekmektedir. Sayfanın en üstünde bulunan video da gerekli tüm aşamaları ve talimatları görebilirsiniz.",
                        }, {
                            title: "WhatsApp'ımı Sisteme Bağlamak İstemiyorum!",
                            content: "Endişenizi anlıyoruz, Fakat Takip işleminin başlaması için bu işlemi yapmanız gerekmektedir. WhatsApp Bağlantınızı yapmazsanız takip işlemi sadece WhatsApp Business numaralar için başlayacaktır.",
                        }, {
                            title: "QR Kodunu Sisteme Bağladığımda Gizliliğim Ne Olacak?",
                            content: "QR kodunu sisteme bağlama işleminde sadece takibi başlatmak için gereken verileri bilgisini alacağız. Mesajlarınız, medyalarınız ve sohbet geçmişiniz hiçbir şekilde sunucularımızda depolanmayacaktır.",
                        }, {
                            title: "QR Kodunu Okuttuğum Zaman Sürekli “Çevrimiçi” Olarak Mı Görüneceğim?",
                            content: "Kesinlikle hayır, QR kodu okutma işleminde gereken veri alındıktan sonra oturumunuz bizim tarafımızdan “çevrimdışı” durumuna alınacaktır.",
                        }, {
                            title: "Takip Ettiğim Kişinin De QR Kodunu Sisteme Bağlaması Gerekiyor Mu?",
                            content: "Hayır, sadece sizin QR kodunuzu sisteme bağlamanız yeterlidir. Takip ettiğiniz numaranın hiçbir işlem yapmasına gerek yoktur.",
                        }, {
                            title: "Takip Edeceğim Kişinin Çevrimiçi Bilgisi Kapalı, Yine De Takip Edebilir Miyim?",
                            content: "Evet, takip ettiğiniz kişinin son görülmesi ya da çevrimiçi durumundan bağımsız bir şekilde takip işleminiz sorunsuz şekilde yapılacaktır.",
                        }, {
                            title: "Takip Ettiğim Kişi Takip Edildiğini Görebilecek Mi?",
                            content: "Hayır, takip işlemlerinden tamamen anonimsiniz. Takip ettiğiniz numara takip edildiğini hiçbir koşulda anlamayacaktır.",
                        }, {
                            title: "Telefon Numarasını Engellediğim Yada Engellendiğim Kişiyi Takip Edebilir Miyim?",
                            content: "Teknik ekibimiz bu konu ile alakalı çalışmalarını devam ettirmektedir. En kısa süre içerisinde engellediğiniz yada engellendiğiniz numaralarında takip edilmesi içinde güncelleme yayınlayacağız.",
                        }, {
                            title: "WhatsApp'ım Sürekli WanLog Sistemine Bağlı Kalmak Zorunda Mı?",
                            content: "Hayır, WhatsApp uygulamanızı sisteme bağladıktan sonra bağlantıyı kesebilirsiniz. Fakat takip işleminin stabil devam etmesi için bağlantıdan çıkış yapmamanızı öneriyoruz.",
                        },
                        {
                            title: "Takip Ettiğim Kişilerin Yanında Ki İşaretler Ne Anlama Geliyor?",
                            content: "Takip başarıyla çalışıyor.",
                            content2: "Takip ettiğiniz kişinin size mesaj atmasını bekleniyor.",
                            content3: "QR kodunu sisteme bağlamanız gerekiyor.",
                            content4: "Takip etmeye hazırlanıyor.",
                        }
                    ],
                    share: {
                        title: "Erişebileceğin Birine Gönder",
                        close: "Kapat",
                    },
                    qr: {
                        shareButton: "Paylaş",
                        waiting: "Bağlantı Bekleniyor",
                        countDownFirst: "Qr kod",
                        countDownSecon: "saniye sonra yenilenecek",
                        connected: "Bağlandı",
                        opinion1: "Telefonunuzda WhatsApp'ı açın",
                        opinion2: "Ayarlar seçeneğine dokunup Bağlı Cihazlar'ı seçin",
                        opinion3: "Cihaz Bağla seçeneğine dokunun",
                        opinion4: "Telefonunuzu bu ekrana doğrultarak kodu tarayın",
                    },
                    link:"https://www.youtube.com/embed/t7jKAVwy8lY?si=Sp2b8KTPcTFl9G3_"
                },
            },
            de: {
                translation: {
                    card: [
                        {
                            title: "Tracking funktioniert erfolgreich.",
                            content: "Wir wissen, dass dies ärgerlich ist, aber aufgrund des neuesten Datenschutz-Updates von WhatsApp ist es nicht mehr möglich, Personen zu folgen, mit denen Sie in den letzten 3 Wochen nicht interagiert haben (SMS und Sprachanrufe). Wir möchten Sie daran erinnern, dass keine WhatsApp-Tracking-Anwendung der Welt mehr funktioniert. Wenn Sie jedoch Ihren QR-Code mit unserem System verbinden, werden die Personen, mit denen Sie gechattet oder einen Sprachanruf getätigt haben, weiterhin aktiv verfolgt.",
                        },
                        {
                            title: "Wie Kann Ich Meine WhatsApp Mit Dem System Verbinden?" +
                                "Sie müssen den QR-Code scannen, den wir auf dem Bildschirm angegeben haben, indem Sie die WhatsApp-Anwendung aufrufen und auf die Option „Ihr Gerät verbinden“ klicken.\n" +
                                "Dazu benötigen Sie 2 Geräte. Sie müssen den QR-Code von einem Gerät anzeigen und den QR-Code von Ihrem eigenen Telefon lesen. Sie können alle notwendigen Schritte und Anweisungen im Video oben auf der Seite sehen.",
                        }, {
                            title: "Ich Möchte Mein WhatsApp Nicht Mit Dem System Verbinden!",
                            content: "Wir verstehen Ihre Bedenken, aber Sie müssen dies tun, damit das Tracking beginnen kann. Wenn Sie Ihre WhatsApp-Verbindung nicht herstellen, beginnt der Tracking-Prozess nur für WhatsApp Business-Nummern.",
                        }, {
                            title: "Was Passiert Mit Meiner Privatsphäre, Wenn Ich Den QR-Code Mit Dem System Verbinde?",
                            content: "Beim Verbinden des QR-Codes mit dem System erhalten wir nur die Daten, die zum Starten des Trackings erforderlich sind. Ihre Nachrichten, Medien und Chatverläufe werden niemals auf unseren Servern gespeichert.",
                        }, {
                            title: "Werde Ich Immer „Online“ Angezeigt, Wenn Ich Den QR-Code Scanne?",
                            content: "Auf keinen Fall, Ihre Sitzung wird von uns in den Offline-Status versetzt, nachdem die für das QR-Code-Scannen erforderlichen Daten empfangen wurden.",
                        }, {
                            title: "Muss Die Person, Der Ich Folge, Den QR-Code Mit Dem System Verbinden?",
                            content: "Nein, Sie müssen nur Ihren QR-Code mit dem System verbinden. Die Nummer, die Sie verfolgen, muss keine Aktion ausführen.",
                        }, {
                            title: "Die Online-Informationen Der Person, Der Ich Folgen Werde, Sind Geschlossen, Kann Ich Trotzdem Folgen?",
                            content: "Ja, Ihr Follow-up-Prozess wird problemlos durchgeführt, unabhängig vom zuletzt gesehenen oder Online-Status der Person, der Sie folgen.",
                        }, {
                            title: "Kann Die Person, Der Ich Folge, Sehen, Dass Sie Verfolgt Wird?",
                            content: "Nein, Sie sind völlig anonym bei der Verfolgung von Transaktionen. Die Nummer, die Sie verfolgt haben, wird nie erfahren, dass sie verfolgt wird.",
                        }, {
                            title: "Kann Ich Der Person Folgen, Deren Telefonnummer Ich Blockiert Oder Blockiert Habe?",
                            content: "Unser technisches Team arbeitet weiterhin an diesem Problem. So bald wie möglich werden wir ein Update veröffentlichen, um die Nummern zu verfolgen, die Sie blockiert oder blockiert haben.",
                        }, {
                            title: "Muss Meine WhatsApp Ständig Mit Dem WanLog System Verbunden Sein?",
                            content: "Nein, Sie können die Verbindung trennen, nachdem Sie Ihre WhatsApp-Anwendung mit dem System verbunden haben. Wir empfehlen Ihnen jedoch, sich nicht von der Verbindung abzumelden, um das Tracking-Verfahren stabil zu halten.",
                        },
                        {
                            title: "Was Bedeuten Die Schilder Neben Personen, Denen Ich Folge?",
                            content: "Tracking funktioniert erfolgreich.",
                            content2: "Ich warte darauf, dass die Person, der du folgst, dir eine Nachricht sendet.",
                            content3: "Du musst den QR-Code mit dem System verbinden.",
                            content4: "Bereite dich darauf vor, zu folgen.\n",
                        }
                    ],
                    share: {
                        title: "An jemanden senden, den Sie erreichen können",
                        close: "Close",
                    },
                    qr: {
                        shareButton: "Teilen",
                        waiting: "Auf Verbindung warten",
                        countDownFirst: "Der QR-Code wird in",
                        countDownSecon: "Sekunden aktualisiert.",
                        connected: "Connected",
                        opinion1: "Öffnen Sie WhatsApp auf Ihrem Telefon",
                        opinion2: "Tippen Sie auf Einstellungen Wählen Sie Verbundene Geräte",
                        opinion3: "Tippen Sie auf Gerät verbinden",
                        opinion4: "Scannen Sie den Code, indem Sie Ihr Telefon auf diesen Bildschirm richten",
                    },
                    link:"https://www.youtube.com/embed/CpCbfc0fAyc?si=Mr9N4v07Zgk-G00g"
                },
            },
            it: {
                translation: {
                    card: [
                        {
                            title: "Il monitoraggio funziona correttamente.",
                            content: "Sappiamo che questo è fastidioso, ma a causa dell'ultimo aggiornamento sulla privacy di WhatsApp, non è più possibile seguire le persone con cui non hai interagito (sms e chiamate vocali) nelle ultime 3 settimane. Ti ricordiamo che nessuna applicazione di tracciamento di WhatsApp al mondo funziona più. Tuttavia, collegando il tuo codice QR al nostro sistema, le persone con cui hai chattato o effettuato una chiamata vocale continueranno ad essere seguite attivamente.",
                        },
                        {
                            title: "Come Posso Connettere Il Mio WhatsApp Al Sistema?",
                            content: "Devi scansionare il codice QR che abbiamo specificato sullo schermo accedendo all'applicazione WhatsApp e facendo clic sull'opzione 'connetti il tuo dispositivo'.\n" +
                                "Avrai bisogno di 2 dispositivi per farlo. Devi visualizzare il codice QR da un dispositivo e leggere il codice QR dal tuo telefono. Puoi vedere tutti i passaggi e le istruzioni necessari nel video nella parte superiore della pagina.",
                        }, {
                            title: "Non Voglio Connettere Il Mio WhatsApp Al Sistema!",
                            content: "Comprendiamo la tua preoccupazione, ma devi farlo per iniziare il monitoraggio. Se non effettui la tua connessione WhatsApp, il processo di tracciamento verrà avviato solo per i numeri WhatsApp Business.",
                        }, {
                            title: "Cosa Accadrà Alla Mia Privacy Quando Collegherò Il Codice QR Al Sistema?",
                            content: "Nel processo di connessione del codice QR al sistema, riceveremo solo i dati necessari per avviare il tracciamento. I tuoi messaggi, contenuti multimediali e cronologia chat non verranno mai archiviati sui nostri server.",
                        }, {
                            title: "Apparirò Sempre 'Online' Quando Scannerizzo Il Codice QR?",
                            content: "Assolutamente no, la tua sessione verrà da noi messa in stato 'offline' dopo aver ricevuto i dati richiesti per la scansione del codice QR.",
                        }, {
                            title: "La Persona Che Seguo Deve Collegare Il QR Code Al Sistema?",
                            content: "No, devi solo collegare il tuo codice QR al sistema. Il numero tracciato non deve intraprendere alcuna azione.",
                        }, {
                            title: "Le Informazioni Online Della Persona Che Seguirò Sono Chiuse, Posso Seguirle Ancora?",
                            content: "Sì, il tuo processo di follow-up verrà eseguito senza problemi, indipendentemente dall'ultimo accesso o dallo stato online della persona che segui.",
                        }, {
                            title: "La Persona Che Seguo Sarà In Grado Di Vedere Che Viene Seguita?",
                            content: "No, sei completamente anonimo dal tracciare le transazioni. Il numero che hai monitorato non saprà mai che è stato monitorato.",
                        }, {
                            title: "Posso Seguire La Persona Di Cui Ho Bloccato O Bloccato Il Numero Di Telefono?",
                            content: "Il nostro team tecnico continua a lavorare su questo problema. Non appena possibile, pubblicheremo un aggiornamento per tenere traccia dei numeri che hai bloccato o bloccato.",
                        }, {
                            title: "Il Mio WhatsApp Deve Essere Costantemente Connesso Al Sistema WanLog?",
                            content: "No, puoi disconnetterti dopo aver connesso la tua applicazione WhatsApp al sistema. Tuttavia, ti consigliamo di non uscire dalla connessione per mantenere stabile il processo di tracciamento.",
                        },
                        {
                            title: "Cosa Significano I Segni Accanto Alle Persone Che Seguo?",
                            content: "Il monitoraggio funziona correttamente.",
                            content2: "In attesa che la persona che segui ti invii un messaggio.",
                            content3: "Devi collegare il codice QR al sistema.",
                            content4: "Preparati a seguire.",
                        }
                    ],
                    share: {
                        title: "Invia a qualcuno che puoi raggiungere",
                        close: "Close",
                    },
                    qr: {
                        shareButton: "Condividere",
                        waiting: "In attesa di connessione",
                        countDownFirst: "Il codice QR si aggiornerà tra .",
                        countDownSecon: "secondi.",
                        connected: "Connected",
                        opinion1: "Apri WhatsApp sul tuo telefono",
                        opinion2: "Tocca su Impostazioni Seleziona Dispositivi connessi",
                        opinion3: "Tocca Connetti dispositivo",
                        opinion4: "Scansiona il codice puntando il telefono su questa schermata",
                    },
                    link:"https://www.youtube.com/embed/nu0TpIEvs2s?si=3XlUfQVYou7O-J5s"
                },

            },
            fr: {
                translation: {
                    card: [
                        {
                            title: "Le suivi fonctionne avec succès.",
                            content: "Nous savons que c'est ennuyeux, mais en raison de la dernière mise à jour de confidentialité de WhatsApp, il n'est plus possible de suivre les personnes avec lesquelles vous n'avez pas interagi (sms et appels vocaux) au cours des 3 dernières semaines. Nous vous rappelons qu'aucune application de suivi WhatsApp dans le monde ne fonctionne plus. Cependant, en connectant votre code QR à notre système, les personnes avec qui vous avez discuté ou passé un appel vocal continueront d'être activement suivies.",
                        },
                        {
                            title: "Comment Puis-Je Connecter Mon WhatsApp Au Système ?",
                            content: "Vous devez scanner le code QR que nous avons spécifié à l'écran en entrant dans l'application WhatsApp et en cliquant sur l'option 'connecter votre appareil'.\n" +
                                "Vous aurez besoin de 2 appareils pour ce faire. Vous devez afficher le code QR à partir d'un appareil et lire le code QR à partir de votre propre téléphone. Vous pouvez voir toutes les étapes et instructions nécessaires dans la vidéo en haut de la page.",
                        }, {
                            title: "Je Ne Veux Pas Connecter Mon WhatsApp Au Système !",
                            content: "Nous comprenons votre inquiétude, mais vous devez le faire pour que le suivi commence. Si vous n'établissez pas votre connexion WhatsApp, le processus de suivi ne démarrera que pour les numéros WhatsApp Business.",
                        }, {
                            title: "Qu'adviendra-T-Il De Ma Vie Privée Lorsque Je Connecterai Le Code QR Au Système ?",
                            content: "Lors du processus de connexion du code QR au système, nous ne recevrons que les données nécessaires pour démarrer le suivi. Vos messages, médias et historique de chat ne seront jamais stockés sur nos serveurs.",
                        }, {
                            title: "Vais-Je Toujours Apparaître 'En Ligne!' Lorsque Je Scanne Le Code QR ?",
                            content: "Absolument pas, votre session sera mise en état 'hors ligne' par nous après réception des données requises pour la numérisation du code QR.",
                        }, {
                            title: "La Personne Que Je Suis Doit-Elle Connecter Le Code QR Au Système ?",
                            content: "Non, il vous suffit de connecter votre code QR au système. Le nombre que vous suivez n'a pas besoin de prendre de mesures.",
                        }, {
                            title: "Les Informations En Ligne De La Personne Que Je Vais Suivre Sont Fermées, Puis-Je Continuer À Suivre ?",
                            content: "Oui, votre processus de suivi se fera sans aucun problème, quel que soit le dernier statut vu ou en ligne de la personne que vous suivez.",
                        }, {
                            title: "La Personne Que Je Suis Pourra-T-Elle Voir Qu'elle Est Suivie ?",
                            content: "Non, vous êtes complètement anonyme du suivi des transactions. Le numéro que vous avez suivi ne saura jamais qu'il est suivi.",
                        }, {
                            title: "Puis-Je Suivre La Personne Dont J'ai Bloqué Ou Bloqué Le Numéro De Téléphone ?",
                            content: "Notre équipe technique continue de travailler sur ce problème. Dès que possible, nous publierons une mise à jour pour suivre les numéros que vous avez bloqués ou bloqués.",
                        }, {
                            title: "Mon WhatsApp Doit-Il Être Constamment Connecté Au Système WanLog ?",
                            content: "Non, vous pouvez vous déconnecter après avoir connecté votre application WhatsApp au système. Cependant, nous vous recommandons de ne pas vous déconnecter de la connexion afin de maintenir la stabilité du processus de suivi.",
                        },
                        {
                            title: "Que Signifient Les Signes À Côté Des Personnes Que Je Suis ?",
                            content: "Le suivi fonctionne avec succès.",
                            content2: "En attente que la personne que vous suivez vous envoie un message.",
                            content3: "Vous devez connecter le code QR au système.",
                            content4: "Préparez-vous à suivre.",
                        }
                    ],
                    share: {
                        title: "Envoyer à quelqu'un que vous pouvez joindre",
                        close: "Close",
                    },
                    qr: {
                        shareButton: "Partager",
                        waiting: "Attente de connexion",
                        countDownFirst: "Le code QR sera actualisé dans",
                        countDownSecon: "secondes.",
                        connected: "Connected",
                        opinion1: "Ouvrez WhatsApp sur votre téléphone",
                        opinion2: "Appuyez sur Paramètres Sélectionnez les appareils connectés",
                        opinion3: "Appuyez sur Connecter l'appareil",
                        opinion4: "Scannez le code en pointant votre téléphone vers cet écran",
                    },
                    link:"https://www.youtube.com/embed/lzsFkmDMhmA?si=VNtkM9zIUfsSqp_q"
                },
            },
            pt: {
                translation: {
                    card: [
                        {
                            title: "O rastreamento funciona com sucesso.",
                            content: "Sabemos que isso é irritante, mas devido à última atualização de privacidade do WhatsApp, não é mais possível seguir pessoas com as quais você não interagiu (mensagens de texto e chamadas de voz) nas últimas 3 semanas. Gostaríamos de lembrar que nenhum aplicativo de rastreamento de WhatsApp no mundo está funcionando mais. No entanto, ao conectar seu código QR ao nosso sistema, as pessoas com quem você conversou ou fez uma chamada de voz continuarão sendo seguidas ativamente.",
                        },
                        {
                            title: "Como Posso Conectar Meu WhatsApp Ao Sistema?",
                            content: "Você precisa escanear o código QR que especificamos na tela, entrando no aplicativo WhatsApp e clicando na opção 'conectar seu dispositivo'.\n" +
                                "Você precisará de 2 dispositivos para fazer isso. Você precisa visualizar o código QR de um dispositivo e ler o código QR do seu próprio telefone. Você pode ver todas as etapas e instruções necessárias no vídeo na parte superior da página.",
                        }, {
                            title: "Não Quero Conectar Meu WhatsApp Ao Sistema!",
                            content: "Entendemos sua preocupação, mas você deve fazer isso para que o Rastreamento comece. Se você não fizer sua Conexão WhatsApp, o processo de rastreamento será iniciado apenas para os números do WhatsApp Business.",
                        }, {
                            title: "O Que Acontecerá Com Minha Privacidade Quando Eu Conectar O QR Code Ao Sistema?",
                            content: "No processo de conexão do código QR ao sistema, receberemos apenas os dados necessários para iniciar o rastreamento. Suas mensagens, mídia e histórico de bate-papo nunca serão armazenados em nossos servidores.",
                        }, {
                            title: "Sempre Aparecerei 'Online' Quando Digitalizar O Código QR?",
                            content: "Absolutamente não, sua sessão será colocada no status 'offline' por nós após o recebimento dos dados necessários para a leitura do código QR.",
                        }, {
                            title: "A Pessoa Que Eu Sigo Precisa Conectar O QR Code Ao Sistema?",
                            content: "Não, você só precisa conectar seu código QR ao sistema. O número que você rastreia não precisa realizar nenhuma ação.",
                        }, {
                            title: "As Informações Online Da Pessoa Que Irei Seguir Estão Fechadas, Posso Seguir Ainda?",
                            content: "Sim, seu processo de acompanhamento será feito sem problemas, independentemente do último acesso ou status online da pessoa que você segue.",
                        }, {
                            title: "A Pessoa Que Sigo Poderá Ver Que Está Sendo Seguida?",
                            content: "Não, você é completamente anônimo para rastrear transações. O número que você rastreou nunca saberá que está sendo rastreado.",
                        }, {
                            title: "Posso Seguir A Pessoa Cujo Número De Telefone Bloqueei Ou Bloqueei?",
                            content: "Nossa equipe técnica continua trabalhando nessa questão. Assim que possível, publicaremos uma atualização para rastrear os números que você bloqueou ou bloqueou.",
                        }, {
                            title: "Meu WhatsApp Precisa Estar Constantemente Conectado Ao Sistema WanLog?",
                            content: "Não, você pode desconectar após conectar seu aplicativo WhatsApp ao sistema. No entanto, recomendamos que você não saia da conexão para manter o processo de rastreamento estável.",
                        },
                        {
                            title: "O Que Significam Os Sinais Ao Lado Das Pessoas Que Sigo?",
                            content: "O rastreamento funciona com sucesso.",
                            content2: "Esperando que a pessoa que você segue lhe envie uma mensagem.",
                            content3: "Você precisa conectar o código QR ao sistema.",
                            content4: "Preparando-se para seguir.",
                        }
                    ],
                    share: {
                        title: "Envie para alguém que você possa alcançar",
                        close: "Close",
                    },
                    qr: {
                        shareButton: "Compartilhar",
                        waiting: "Aguardando conexão",
                        countDownFirst: "O código QR será atualizado em",
                        countDownSecon: "segundos.",
                        connected: "Connected",
                        opinion1: "Abra o WhatsApp no seu telefone",
                        opinion2: "Toque em Configurações Selecione Dispositivos conectados",
                        opinion3: "Toque em Conectar dispositivo",
                        opinion4: "Escaneie o código apontando seu telefone para esta tela",
                    },
                    link:"https://www.youtube.com/embed/TC3q1JGsSZQ?si=r4nH9WnEfqkgOlxU"
                },

            },
            es: {
                translation: {
                    card: [
                        {
                            title: "El seguimiento funciona correctamente.",
                            content: "Sabemos que esto es molesto, pero debido a la última actualización de privacidad de WhatsApp, ya no es posible seguir a las personas con las que no has interactuado (mensajes de texto y llamadas de voz) en las últimas 3 semanas. Te recordamos que ya no funciona ninguna aplicación de seguimiento de WhatsApp en el mundo. Sin embargo, al conectar su código QR a nuestro sistema, las personas con las que ha chateado o realizado una llamada de voz seguirán siendo seguidas activamente.",
                        },
                        {
                            title: "¿Cómo Puedo Conectar Mi WhatsApp Al Sistema?",
                            content: "Debe escanear el código QR que especificamos en la pantalla ingresando a la aplicación WhatsApp y haciendo clic en la opción 'conectar su dispositivo'.\n" +
                                "Necesitarás 2 dispositivos para hacer esto. Debe ver el código QR desde un dispositivo y leer el código QR desde su propio teléfono. Puede ver todos los pasos e instrucciones necesarios en el video en la parte superior de la página.",
                        }, {
                            title: "¡No Quiero Conectar Mi WhatsApp Al Sistema!",
                            content: "Entendemos su preocupación, pero debe hacer esto para que comience el seguimiento. Si no realiza su Conexión de WhatsApp, el proceso de seguimiento solo comenzará para los números de WhatsApp Business.",
                        }, {
                            title: "¿Qué Pasará Con Mi Privacidad Cuando Conecte El Código QR Al Sistema?",
                            content: "En el proceso de conexión del código QR al sistema, solo recibiremos los datos necesarios para iniciar el seguimiento. Sus mensajes, medios e historial de chat nunca se almacenarán en nuestros servidores.",
                        }, {
                            title: "¿Apareceré Siempre 'En Línea' Cuando Escanee El Código QR?",
                            content: "Absolutamente no, su sesión pasará al estado 'fuera de línea' después de que se reciban los datos necesarios para escanear el código QR.",
                        }, {
                            title: "¿La Persona A La Que Sigo Necesita Conectar El Código QR Al Sistema?",
                            content: "No, solo necesitas conectar tu código QR al sistema. El número que rastrea no necesita tomar ninguna acción.",
                        }, {
                            title: "La Información En Línea De La Persona Que Seguiré Está Cerrada, ¿Puedo Seguir Todavía?",
                            content: "Sí, tu proceso de seguimiento se realizará sin ningún problema, independientemente del último estado visto o en línea de la persona a la que sigues.",
                        }, {
                            title: "¿La Persona A La Que Sigo Podrá Ver Que Está Siendo Seguida?",
                            content: "No, usted es completamente anónimo para rastrear transacciones. El número que rastreó nunca sabrá que está siendo rastreado.",
                        }, {
                            title: "¿Puedo Seguir A La Persona Cuyo Número De Teléfono Bloqueé O Bloqueé?",
                            content: "Nuestro equipo técnico sigue trabajando en este tema. Tan pronto como sea posible, publicaremos una actualización para rastrear los números que ha bloqueado o bloqueado.",
                        }, {
                            title: "¿Mi WhatsApp Tiene Que Estar Constantemente Conectado Al Sistema WanLog?",
                            content: "No, puede desconectarse después de conectar su aplicación WhatsApp al sistema. Sin embargo, le recomendamos que no cierre la sesión de la conexión para mantener estable el proceso de seguimiento.",
                        },
                        {
                            title: "¿Qué Significan Los Letreros Junto A Las Personas Que Sigo?",
                            content: "El seguimiento funciona correctamente.",
                            content2: "Esperando a que la persona a la que sigues te envíe un mensaje.",
                            content3: "Debes conectar el código QR al sistema.",
                            content4: "Preparándote para seguir.",
                        }
                    ],
                    share: {
                        title: "Escanee el código apuntando su teléfono a esta pantalla",
                        close: "Close",
                    },
                    qr: {
                        shareButton: "Cuota",
                        waiting: "Esperando la conexión",
                        countDownFirst: "El código QR se actualizará en",
                        countDownSecon: "segundos.",
                        connected: "Connected",
                        opinion1: "Abre WhatsApp en tu teléfono",
                        opinion2: "Toque Configuración Seleccione Dispositivos conectados",
                        opinion3: "Toca Conectar dispositivo",
                        opinion4: "Escanee el código apuntando su teléfono a esta pantalla",
                    },
                    link:"https://www.youtube.com/embed/4NYuxQOkuV0?si=I47hl28H54M-C3k6"
                },

            },
            nl: {
                translation: {
                    card: [
                        {
                            title: "Tracking werkt succesvol.",
                            content: "We weten dat dit vervelend is, maar vanwege de laatste privacy-update van WhatsApp is het niet langer mogelijk om mensen te volgen met wie je de afgelopen 3 weken geen interactie hebt gehad (sms'en en bellen). We willen u eraan herinneren dat geen enkele WhatsApp-trackingtoepassing ter wereld meer werkt. Maar door je QR-code aan ons systeem te koppelen, blijven de mensen met wie je hebt gechat of gebeld actief worden gevolgd.",
                        },
                        {
                            title: "Hoe Kan Ik Mijn WhatsApp Verbinden Met Het Systeem?",
                            content: "U moet de QR-code scannen die we op het scherm hebben opgegeven door de WhatsApp-applicatie te openen en op de optie 'uw apparaat verbinden' te klikken.\n" +
                                "Je hebt hiervoor 2 apparaten nodig. U moet de QR-code bekijken vanaf een apparaat en de QR-code lezen vanaf uw eigen telefoon. Je kunt alle benodigde stappen en instructies zien in de video bovenaan de pagina.",
                        }, {
                            title: "Ik Wil Mijn WhatsApp Niet Verbinden Met Het Systeem!",
                            content: "We begrijpen uw bezorgdheid, maar u moet dit doen om het volgen te laten beginnen. Als je je WhatsApp-verbinding niet maakt, start het volgproces alleen voor WhatsApp Business-nummers.",
                        }, {
                            title: "Wat Gebeurt Er Met Mijn Privacy Als Ik De QR-Code Aan Het Systeem Koppel?",
                            content: "Tijdens het verbinden van de QR-code met het systeem ontvangen we alleen de gegevens die nodig zijn om de tracking te starten. Je berichten, media- en chatgeschiedenis worden nooit op onze servers opgeslagen.",
                        }, {
                            title: "Zal Ik Altijd 'Online' Verschijnen Als Ik De QR-Code Scan?",
                            content: "Absoluut niet, uw sessie wordt door ons in de 'offline'-status gezet nadat de gegevens die nodig zijn voor het scannen van de QR-code zijn ontvangen.",
                        }, {
                            title: "Moet De Persoon Die Ik Volg De QR-Code Verbinden Met Het Systeem?",
                            content: "Nee, u hoeft alleen uw QR-code aan het systeem te koppelen. Het nummer dat u volgt hoeft geen actie te ondernemen.",
                        }, {
                            title: "De Online Informatie Van De Persoon Die Ik Ga Volgen Is Gesloten, Kan Ik Nog Volgen?",
                            content: "Ja, uw opvolgproces verloopt zonder problemen, ongeacht de laatst geziene of online status van de persoon die u volgt.",
                        }, {
                            title: "Kan De Persoon Die Ik Volg Zien Dat Hij Of Zij Wordt Gevolgd?",
                            content: "Nee, u bent volledig anoniem bij het volgen van transacties. Het nummer dat u hebt gevolgd, zal nooit weten dat het wordt gevolgd.",
                        }, {
                            title: "Kan Ik De Persoon Volgen Wiens Telefoonnummer Ik Heb Geblokkeerd Of Geblokkeerd?",
                            content: "Ons technische team blijft aan dit probleem werken. We zullen zo snel mogelijk een update publiceren om de nummers bij te houden die je hebt geblokkeerd of geblokkeerd.",
                        }, {
                            title: "Moet Mijn WhatsApp Constant Verbonden Zijn Met Het WanLog-Systeem?",
                            content: "Nee, je kunt de verbinding verbreken nadat je je WhatsApp-applicatie met het systeem hebt verbonden. We raden u echter aan om niet uit te loggen bij de verbinding om het trackingproces stabiel te houden.",
                        },
                        {
                            title: "Wat Betekenen De Tekens Naast Mensen Die Ik Volg?",
                            content: "Tracking werkt succesvol.",
                            content2: "Wachten tot de persoon die je volgt je een bericht stuurt.",
                            content3: "Je moet de QR-code verbinden met het systeem.",
                            content4: "Klaar om te volgen.",
                        }
                    ],
                    qr: {
                        shareButton: "Deel",
                        waiting: "Wachten op verbinding",
                        countDownFirst: "De QR-code wordt over",
                        countDownSecon: "seconden vernieuwd.",
                        connected: "Connected",
                        opinion1: "Open WhatsApp op je telefoon",
                        opinion2: "Tik op Instellingen Selecteer Verbonden apparaten",
                        opinion3: "Tik op Apparaat verbinden",
                        opinion4: "Scan de code door je telefoon naar dit scherm te richten",
                    },
                    share: {
                        title: "Stuur naar iemand die u kunt bereiken",
                        close: "Close",
                    },
                    link:"https://www.youtube.com/embed/7cJQIyDfQMc?si=4X-bfOA_eFbzTGBR"
                },

            },
            id: {
                translation: {
                    card: [
                        {
                            title: "Pelacakan berhasil berhasil.",
                            content: "Kami tahu ini menjengkelkan, tetapi karena pembaruan privasi terbaru WhatsApp, Anda tidak dapat lagi mengikuti orang yang belum berinteraksi dengan Anda (SMS dan panggilan suara) dalam 3 minggu terakhir. Kami ingin mengingatkan Anda bahwa tidak ada lagi aplikasi pelacakan WhatsApp di dunia yang berfungsi. Namun, dengan menghubungkan kode QR Anda ke sistem kami, orang yang Anda ajak mengobrol atau melakukan panggilan suara akan terus diikuti secara aktif.",
                        },
                        {
                            title: "Bagaimana Saya Dapat Menghubungkan WhatsApp Saya Ke Sistem?",
                            content: "Anda perlu memindai kode QR yang kami tentukan di layar dengan masuk ke aplikasi WhatsApp dan mengklik opsi 'sambungkan perangkat Anda'.\n" +
                                "Anda memerlukan 2 perangkat untuk melakukannya. Anda perlu melihat kode QR dari perangkat dan membaca kode QR dari ponsel Anda sendiri. Anda dapat melihat semua langkah dan instruksi yang diperlukan dalam video di bagian atas halaman.",
                        }, {
                            title: "Saya Tidak Ingin Menghubungkan WhatsApp Saya Ke Sistem!",
                            content: "Kami memahami kekhawatiran Anda, tetapi Anda harus melakukan ini agar Pelacakan dapat dimulai. Jika Anda tidak melakukan Koneksi WhatsApp, proses pelacakan hanya akan dimulai untuk nomor WhatsApp Business.",
                        }, {
                            title: "Apa Yang Akan Terjadi Pada Privasi Saya Saat Saya Menghubungkan Kode QR Ke Sistem?",
                            content: "Dalam proses menghubungkan kode QR ke sistem, kami hanya akan menerima data yang diperlukan untuk memulai pelacakan. Pesan, media, dan riwayat obrolan Anda tidak akan pernah disimpan di server kami.",
                        }, {
                            title: "Apakah Saya Akan Selalu Tampil 'Online' Ketika Saya Memindai Kode QR?",
                            content: "Sama sekali tidak, sesi Anda akan kami jadikan status 'offline' setelah data yang diperlukan untuk pemindaian kode QR diterima.",
                        }, {
                            title: "Apakah Orang Yang Saya Ikuti Perlu Menghubungkan Kode QR Ke Sistem?",
                            content: "Tidak, Anda hanya perlu menghubungkan kode QR Anda ke sistem. Nomor yang Anda lacak tidak perlu melakukan tindakan apa pun.",
                        }, {
                            title: "Informasi Online Orang Yang Akan Saya Ikuti Sudah Ditutup, Apakah Saya Masih Bisa Mengikuti?",
                            content: "Ya, proses tindak lanjut Anda akan dilakukan tanpa masalah, terlepas dari status terakhir dilihat atau online dari orang yang Anda ikuti.",
                        }, {
                            title: "Apakah Orang Yang Saya Ikuti Dapat Melihat Bahwa Mereka Sedang Diikuti?",
                            content: "Tidak, Anda sepenuhnya anonim dari pelacakan transaksi. Nomor yang Anda lacak tidak akan pernah tahu bahwa sedang dilacak.",
                        }, {
                            title: "Bisakah Saya Mengikuti Orang Yang Nomor Teleponnya Saya Blokir Atau Blokir?",
                            content: "Tim teknis kami terus menangani masalah ini. Sesegera mungkin, kami akan menerbitkan pembaruan untuk melacak nomor yang telah Anda blokir atau blokir.",
                        }, {
                            title: "Apakah WhatsApp Saya Harus Selalu Terkoneksi Dengan Sistem WanLog?",
                            content: "Tidak, Anda dapat memutuskan sambungan setelah menghubungkan aplikasi WhatsApp Anda ke sistem. Namun, kami menyarankan Anda untuk tidak keluar dari koneksi agar proses pelacakan tetap stabil.",
                        },
                        {
                            title: "Apa Arti Tanda Di Samping Orang Yang Saya Ikuti?",
                            content: "Pelacakan berhasil berhasil.",
                            content2: "Menunggu orang yang Anda ikuti mengirimkan pesan kepada Anda.",
                            content3: "Anda perlu menghubungkan kode QR ke sistem.",
                            content4: "Bersiap untuk mengikuti.",
                        }
                    ],
                    qr: {
                        shareButton: "Membagikan",
                        waiting: "Menunggu koneksi",
                        countDownFirst: "Kode QR akan disegarkan dalam",
                        countDownSecon: "detik.",
                        connected: "Connected",
                        opinion1: "Buka WhatsApp di ponsel Anda",
                        opinion2: "Ketuk Pengaturan Pilih Perangkat Terhubung",
                        opinion3: "Ketuk Hubungkan Perangkat",
                        opinion4: "Pindai kode dengan mengarahkan ponsel Anda ke layar ini",
                    },
                    share: {
                        title: "Kirim ke Seseorang yang Dapat Anda Jangkau",
                        close: "Close",
                    },
                    link:"https://www.youtube.com/embed/m4ggDjVWNRY?si=5pichLaKXOVBO3sH"
                },

            },
            ru: {
                translation: {
                    card: [
                        {
                            title: "Отслеживание успешно работает.",
                            content: "Мы знаем, что это раздражает, но из-за последнего обновления конфиденциальности WhatsApp больше невозможно подписаться на людей, с которыми вы не общались (текстовые сообщения и голосовые звонки) в течение последних 3 недель. Напоминаем, что ни одно приложение для отслеживания WhatsApp в мире больше не работает. Однако, подключив свой QR-код к нашей системе, люди, с которыми вы общались в чате или сделали голосовой вызов, будут по-прежнему активно отслеживаться.",
                        },
                        {
                            title: "Как Я Могу Подключить Свой WhatsApp К Системе?",
                            content: "Вам необходимо отсканировать QR-код, который мы указали на экране, войдя в приложение WhatsApp и нажав на опцию «подключить ваше устройство».\n" +
                                "Для этого вам понадобится 2 устройства. Вам необходимо просмотреть QR-код с устройства и прочитать QR-код со своего телефона. Все необходимые шаги и инструкции вы можете увидеть в видео вверху страницы.",
                        }, {
                            title: "Я Не Хочу Подключать Свой WhatsApp К Системе!",
                            content: "Мы понимаем вашу озабоченность, но вы должны сделать это, чтобы начать отслеживание. Если вы не подключитесь к WhatsApp, процесс отслеживания начнется только для номеров WhatsApp Business.",
                        }, {
                            title: "Что Произойдет С Моей Конфиденциальностью, Когда Я Подключу QR-Код К Системе?",
                            content: "В процессе подключения QR-кода к системе мы получим только те данные, которые необходимы для начала отслеживания. Ваши сообщения, мультимедиа и история чатов никогда не будут храниться на наших серверах.",
                        }, {
                            title: "Буду Ли Я Всегда Отображаться Как 'Онлайн' При Сканировании QR-Кода?",
                            content: "Абсолютно нет, ваша сессия будет переведена нами в 'офлайн' статус после получения данных, необходимых для сканирования QR-кода.",
                        }, {
                            title: "Нужно Ли Человеку, За Которым Я Следую, Подключать QR-Код К Системе?",
                            content: "Нет, вам просто нужно подключить свой QR-код к системе. Номер, который вы отслеживаете, не должен предпринимать никаких действий.",
                        }, {
                            title: "Онлайн-Информация Человека, За Которым Я Буду Следить, Закрыта, Могу Ли Я Подписаться?",
                            content: "Да, ваш процесс отслеживания будет выполнен без каких-либо проблем, независимо от того, в последний раз видели или онлайн-статус человека, на которого вы подписаны.",
                        }, {
                            title: "Сможет Ли Человек, За Которым Я Слежу, Видеть, Что За Ним Следят?",
                            content: "Нет, вы полностью анонимны от отслеживания транзакций. Номер, который вы отслеживаете, никогда не узнает, что он отслеживается.",
                        }, {
                            title: "Могу Ли Я Подписаться На Человека, Чей Номер Телефона Я Заблокировал Или Заблокировал?",
                            content: "Наша техническая команда продолжает работать над этой проблемой. Как можно скорее мы опубликуем обновление для отслеживания заблокированных или заблокированных вами номеров.",
                        }, {
                            title: "Должен Ли Мой WhatsApp Быть Постоянно Подключен К Системе WanLog?",
                            content: "Нет, вы можете отключиться после подключения приложения WhatsApp к системе. Однако мы рекомендуем вам не выходить из соединения, чтобы процесс отслеживания оставался стабильным.",
                        },
                        {
                            title: "Что Означают Знаки Рядом С Людьми, За Которыми Я Следую?",
                            content: "Отслеживание успешно работает.",
                            content2: "Ждем, пока человек, на которого вы подписаны, отправит вам сообщение.",
                            content3: "Вам необходимо подключить QR-код к системе.",
                            content4: "Готовимся подписаться.",
                        }
                    ],
                    qr: {
                        shareButton: "Делиться",
                        waiting: "Ожидание соединения",
                        countDownFirst: "QR-код обновится через",
                        countDownSecon: "секунд.",
                        connected: "Connected",
                        opinion1: "Откройте WhatsApp на своем телефоне",
                        opinion2: "Нажмите «Настройки», выберите «Подключенные устройства».",
                        opinion3: "Нажмите на Подключить устройство",
                        opinion4: "Отсканируйте код, наведя телефон на этот экран.",
                    },
                    share: {
                        title: "Отправить тому, с кем вы можете связаться",
                        close: "Close",
                    },
                    link:"https://www.youtube.com/embed/9arxcDYJ-2Y?si=rdlrLxc5VNTNosKp"
                },

            },
            hi: {
                translation: {
                    card: [
                        {
                            title: " ट्रैकिंग सफलतापूर्वक काम करती है।",
                            content: "हम जानते हैं कि यह परेशान करने वाला है, लेकिन व्हाट्सएप के नवीनतम गोपनीयता अपडेट के कारण, पिछले 3 हफ्तों में उन लोगों का अनुसरण करना संभव नहीं है जिनसे आपने (टेक्स्टिंग और वॉयस कॉल) बातचीत नहीं की है। हम आपको याद दिलाना चाहेंगे कि दुनिया में कोई भी व्हाट्सएप ट्रैकिंग एप्लिकेशन अब काम नहीं कर रहा है। हालांकि, आपके क्यूआर कोड को हमारे सिस्टम से जोड़कर, जिन लोगों के साथ आपने चैट या वॉयस कॉल की है, उनका सक्रिय रूप से अनुसरण किया जाता रहेगा।",
                        },
                        {
                            title: "मैं अपने व्हाट्सएप को सिस्टम से कैसे जोड़ सकता हूं?",
                            content: "आपको व्हाट्सएप एप्लिकेशन में प्रवेश करके और 'कनेक्ट योर डिवाइस' विकल्प पर क्लिक करके स्क्रीन पर हमारे द्वारा निर्दिष्ट क्यूआर कोड को स्कैन करना होगा।\n" +
                                "ऐसा करने के लिए आपको 2 उपकरणों की आवश्यकता होगी। आपको डिवाइस से क्यूआर कोड देखने और अपने फोन से क्यूआर कोड पढ़ने की जरूरत है। आप पृष्ठ के शीर्ष पर वीडियो में सभी आवश्यक कदम और निर्देश देख सकते हैं।",
                        }, {
                            title: "मैं अपने व्हाट्सएप को सिस्टम से कनेक्ट नहीं करना चाहता!",
                            content: "हम आपकी चिंता को समझते हैं, लेकिन ट्रैकिंग शुरू करने के लिए आपको यह करना होगा। यदि आप अपना व्हाट्सएप कनेक्शन नहीं बनाते हैं, तो ट्रैकिंग प्रक्रिया केवल व्हाट्सएप बिजनेस नंबरों के लिए शुरू होगी।",
                        }, {
                            title: "जब मैं क्यूआर कोड को सिस्टम से जोड़ता हूं तो मेरी गोपनीयता का क्या होगा?",
                            content: "क्यूआर कोड को सिस्टम से जोड़ने की प्रक्रिया में, हम केवल ट्रैकिंग शुरू करने के लिए आवश्यक डेटा प्राप्त करेंगे। आपके संदेश, मीडिया और चैट इतिहास हमारे सर्वर पर कभी भी संगृहीत नहीं किए जाएंगे।",
                        }, {
                            title: "क्यूआर कोड स्कैन करने पर क्या मैं हमेशा 'ऑनलाइन' दिखूंगा?",
                            content: "बिल्कुल नहीं, क्यूआर कोड स्कैनिंग के लिए आवश्यक डेटा प्राप्त होने के बाद आपके सत्र को हमारे द्वारा 'ऑफ़लाइन' स्थिति में ले लिया जाएगा।",
                        }, {
                            title: "क्या मेरे द्वारा अनुसरण किए जाने वाले व्यक्ति को QR कोड को सिस्टम से कनेक्ट करने की आवश्यकता है?",
                            content: "नहीं, आपको बस अपने क्यूआर कोड को सिस्टम से जोड़ना होगा। आपके द्वारा ट्रैक किए जाने वाले नंबर को कोई कार्रवाई करने की आवश्यकता नहीं है।",
                        }, {
                            title: "मैं जिस व्यक्ति का अनुसरण करूंगा उसकी ऑनलाइन जानकारी बंद है, क्या मैं अभी भी अनुसरण कर सकता हूं?",
                            content: "हां, आपकी फॉलो-अप प्रक्रिया बिना किसी समस्या के पूरी हो जाएगी, भले ही आप जिस व्यक्ति को फॉलो करते हैं, उसके आखिरी बार देखे जाने या ऑनलाइन स्थिति की परवाह किए बिना।",
                        }, {
                            title: "मैं जिस व्यक्ति का अनुसरण कर रहा हूं क्या वह यह देख पाएगा कि उनका अनुसरण किया जा रहा है?",
                            content: "नहीं, आप लेन-देन पर नज़र रखने से पूरी तरह गुमनाम हैं। आपके द्वारा ट्रैक किए गए नंबर को कभी पता नहीं चलेगा कि उसे ट्रैक किया जा रहा है।",
                        }, {
                            title: "क्या मैं उस व्यक्ति को फॉलो कर सकता हूं जिसका फोन नंबर मैंने ब्लॉक या ब्लॉक किया है?",
                            content: "हमारी तकनीकी टीम इस मुद्दे पर काम कर रही है। जितनी जल्दी हो सके, हम आपके द्वारा ब्लॉक या ब्लॉक किए गए नंबरों को ट्रैक करने के लिए एक अपडेट प्रकाशित करेंगे।",
                        }, {
                            title: "क्या मेरे व्हाट्सएप को लगातार वैलॉगर सिस्टम से जुड़ा रहना है?",
                            content: "नहीं, आप अपने व्हाट्सएप एप्लिकेशन को सिस्टम से कनेक्ट करने के बाद डिस्कनेक्ट कर सकते हैं। हालाँकि, हम अनुशंसा करते हैं कि ट्रैकिंग प्रक्रिया को स्थिर रखने के लिए आप कनेक्शन से लॉग आउट न करें।",
                        },
                        {
                            title: "मेरे द्वारा अनुसरण किए जाने वाले लोगों के आगे के संकेतों का क्या मतलब है?",
                            content: "ट्रैकिंग सफलतापूर्वक काम करती है।",
                            content2: "आप जिस व्यक्ति को फ़ॉलो कर रहे हैं उसका आपको संदेश भेजने का इंतज़ार किया जा रहा है।",
                            content3: "आपको QR कोड को सिस्टम से जोड़ना होगा।",
                            content4: "फ़ॉलो करने के लिए तैयार हो रहे हैं।",
                        }
                    ],
                    qr: {
                        shareButton: "शेयर करना",
                        waiting: "जुडने के लिए इंतजार",
                        countDownFirst: "QR कोड",
                        countDownSecon: "सेकेंड में रीफ्रेश हो जाएगा।",
                        connected: "Connected",
                        opinion1: "अपने फोन पर व्हाट्सएप खोलें",
                        opinion2: "सेटिंग्स पर टैप करें कनेक्टेड डिवाइस चुनें",
                        opinion3: "कनेक्ट डिवाइस पर टैप करें",
                        opinion4: "अपने फ़ोन को इस स्क्रीन पर इंगित करके कोड को स्कैन करें",
                    },
                    share: {
                        title: "किसी ऐसे व्यक्ति को भेजें जिस तक आप पहुँच सकते हैं",
                        close: "Close",
                    },
                    link:"https://www.youtube.com/embed/jkp148mue88?si=kpWfJEZxEM2gCoHN"
                },

            },
            ar: {
                translation: {
                    card: [
                        {
                            title: "يعمل التتبع بنجاح.",
                            content: "نعلم أن هذا أمر مزعج ، ولكن نظرًا لتحديث الخصوصية الأخير في WhatsApp ، لم يعد من الممكن متابعة الأشخاص الذين لم تتفاعل معهم (الرسائل النصية والمكالمات الصوتية) في الأسابيع الثلاثة الماضية. نود أن نذكرك أنه لا يوجد تطبيق تتبع WhatsApp في العالم يعمل بعد الآن. ومع ذلك ، من خلال ربط رمز الاستجابة السريعة الخاص بك بنظامنا ، سيستمر متابعة الأشخاص الذين تحدثت معهم أو أجريت مكالمة صوتية بنشاط.",
                        },
                        {
                            title: "كيف يمكنني توصيل WhatsApp الخاص بي بالنظام؟",
                            content: "تحتاج إلى مسح رمز الاستجابة السريعة الذي حددناه على الشاشة عن طريق الدخول إلى تطبيق WhatsApp والنقر على خيار \"توصيل جهازك\".\n" +
                                "ستحتاج إلى جهازين للقيام بذلك. تحتاج إلى عرض رمز الاستجابة السريعة من جهاز وقراءة رمز الاستجابة السريعة من هاتفك الخاص. يمكنك مشاهدة جميع الخطوات والإرشادات اللازمة في الفيديو أعلى الصفحة.",
                        }, {
                            title: "لا أريد توصيل WhatsApp الخاص بي بالنظام!",
                            content: "نحن نتفهم قلقك ، ولكن يجب عليك القيام بذلك لبدء التتبع. إذا لم تقم بإجراء اتصال WhatsApp الخاص بك ، فستبدأ عملية التتبع لأرقام WhatsApp Business فقط.",
                        }, {
                            title: "ماذا سيحدث لخصوصيتي عندما أقوم بتوصيل رمز الاستجابة السريعة بالنظام؟",
                            content: "في عملية توصيل رمز الاستجابة السريعة بالنظام ، سوف نتلقى فقط البيانات المطلوبة لبدء التتبع. لن يتم تخزين رسائلك ووسائطك وسجل الدردشة على خوادمنا أبدًا.",
                        }, {
                            title: "هل سأظهر دائمًا ' عبر الإنترنت ' عند مسح رمز الاستجابة السريعة ضوئيًا؟",
                            content: "بالتأكيد لا ، سيتم تحويل جلستك إلى حالة 'غير متصل' من قبلنا بعد استلام البيانات المطلوبة لمسح رمز الاستجابة السريعة.",
                        }, {
                            title: "هل يحتاج الشخص الذي أتبعه إلى توصيل رمز الاستجابة السريعة بالنظام؟",
                            content: "لا ، ما عليك سوى توصيل رمز الاستجابة السريعة الخاص بك بالنظام. الرقم الذي تتبعه لا يحتاج إلى اتخاذ أي إجراء.",
                        }, {
                            title: "معلومات الإنترنت الخاصة بالشخص الذي سأتبعه مغلقة ، هل يمكنني المتابعة بعد؟",
                            content: "نعم ، ستتم عملية المتابعة الخاصة بك دون أي مشاكل ، بغض النظر عن آخر ظهور أو حالة اتصال الشخص الذي تتابعه.",
                        }, {
                            title: "هل سيتمكن الشخص الذي أتابعه من رؤية أنه تتم متابعته؟",
                            content: "لا ، أنت مجهول الهوية تمامًا من تتبع المعاملات. الرقم الذي تتبعه لن يعرف أبدًا أنه يتم تعقبه.",
                        }, {
                            title: "هل يمكنني متابعة الشخص الذي قمت بحظر رقم هاتفه أو حظره؟",
                            content: "يواصل فريقنا الفني العمل على هذه المشكلة. في أقرب وقت ممكن ، سننشر تحديثًا لتتبع الأرقام التي حظرتها أو حظرتها.",
                        }, {
                            title: "هل يجب أن يكون تطبيق WhatsApp الخاص بي متصلاً باستمرار بنظام WanLog؟",
                            content: "لا ، يمكنك قطع الاتصال بعد توصيل تطبيق WhatsApp الخاص بك بالنظام. ومع ذلك ، نوصي بعدم تسجيل الخروج من الاتصال للحفاظ على استقرار عملية التعقب.",
                        },
                        {
                            title: "ماذا تعني الإشارات الموجودة بجانب الأشخاص الذين أتبعهم؟",
                            content: " يعمل التتبع بنجاح.",
                            content2: " في انتظار أن يرسل لك الشخص الذي تتابعه رسالة.",
                            content3: "تحتاج إلى توصيل رمز الاستجابة السريعة بالنظام.",
                            content4: " الاستعداد للمتابعة.",
                        }
                    ],
                    qr: {
                        shareButton: "يشارك",
                        waiting: "في انتظار الاتصال",
                        countDownFirst: "ثانية.",
                        countDownSecon: "سيتم تحديث رمز الاستجابة السريعة خلال",
                        connected: "Connected",
                        opinion1: "افتح تطبيق WhatsApp على هاتفك",
                        opinion2: "اضغط على الإعدادات حدد الأجهزة المتصلة",
                        opinion3: "اضغط على توصيل الجهاز",
                        opinion4: "امسح الرمز ضوئيًا عن طريق توجيه هاتفك إلى هذه الشاشة",
                    },
                    share: {
                        title: "امسح الرمز ضوئيًا عن طريق توجيه هاتفك إلى هذه الشاشة",
                        close: "Close",
                    },
                    link:"https://www.youtube.com/embed/ecuqA_9Taoo?si=k_D7TCV3qzcnVssF"
                },

            },
            he: {
                translation: {
                    card: [
                        {
                            title: "מעקב עובד בהצלחה.",
                            content: "אנחנו יודעים שזה מעצבן, אבל בגלל עדכון הפרטיות האחרון של WhatsApp, לא ניתן עוד לעקוב אחר אנשים שלא קיימת איתם אינטראקציה (הודעות טקסט ושיחות קוליות) ב-3 השבועות האחרונים. ברצוננו להזכיר לכם שאף אפליקציית מעקב של WhatsApp בעולם לא עובדת יותר. עם זאת, על ידי חיבור קוד ה-QR שלך למערכת שלנו, ימשיכו לעקוב באופן פעיל אחר האנשים שאיתם שוחחת או שיחת שיחה קולית.",
                        },
                        {
                            title: "איך אני יכול לחבר את הוואטסאפ שלי למערכת?",
                            content: "עליך לסרוק את קוד ה-QR שציינו על המסך על ידי כניסה לאפליקציית WhatsApp ולחיצה על האפשרות 'חבר את המכשיר שלך'.\n" +
                                "תזדקק ל-2 מכשירים כדי לעשות זאת. עליך להציג את קוד ה-QR ממכשיר ולקרוא את קוד ה-QR מהטלפון שלך. את כל השלבים וההנחיות הנחוצים תוכלו לראות בסרטון בראש העמוד.",
                        }, {
                            title: "אני לא רוצה לחבר את הוואטסאפ שלי למערכת!",
                            content: "אנו מבינים את דאגתך, אך עליך לעשות זאת כדי שהמעקב יתחיל. אם לא תבצע את חיבור ה-WhatsApp שלך, תהליך המעקב יתחיל רק עבור מספרי WhatsApp Business.",
                        }, {
                            title: "מה יקרה לפרטיות שלי כשאחבר את קוד ה-QR למערכת?",
                            content: "בתהליך חיבור קוד ה-QR למערכת, נקבל רק את הנתונים הנדרשים לתחילת המעקב. ההודעות, המדיה והיסטוריית הצ'אט שלך לעולם לא יאוחסנו בשרתים שלנו.",
                        }, {
                            title: "האם תמיד יופיע 'מקוון' כשאסרוק את קוד ה-QR?",
                            content: "ממש לא, הפגישה שלך תילקח על ידינו למצב 'לא מקוון' לאחר קבלת הנתונים הנדרשים לסריקת קוד QR.",
                        }, {
                            title: "האם האדם שאני עוקב אחריו צריך לחבר את קוד ה-QR למערכת?",
                            content: "לא, אתה רק צריך לחבר את קוד ה-QR שלך למערכת. המספר שאתה עוקב אחריו אינו צריך לנקוט פעולה כלשהי.",
                        }, {
                            title: "המידע המקוון של האדם שאחריו אעקוב סגור, האם אוכל לעקוב עדיין?",
                            content: "כן, תהליך המעקב שלך יתבצע ללא כל בעיה, ללא קשר לסטטוס שנראה לאחרונה או לסטטוס המקוון של האדם שאתה עוקב אחריו.",
                        }, {
                            title: "האם האדם שאני עוקב אחריו יוכל לראות שעוקבים אחריו?",
                            content: "לא, אתה אנונימי לחלוטין ממעקב אחר עסקאות. המספר שעקבתם אחריו לעולם לא יידע שעוקבים אחריו.",
                        }, {
                            title: "האם אני יכול לעקוב אחר האדם שאת מספר הטלפון שלו חסמתי או חסמתי?",
                            content: "הצוות הטכני שלנו ממשיך לעבוד על הנושא הזה. בהקדם האפשרי, נפרסם עדכון למעקב אחר המספרים שחסמתם או חסמתם.",
                        }, {
                            title: "האם הווטסאפ שלי חייב להיות מחובר כל הזמן למערכת WanLog?",
                            content: "לא, אתה יכול להתנתק לאחר חיבור אפליקציית WhatsApp שלך למערכת. עם זאת, אנו ממליצים לא לצאת מהחיבור כדי לשמור על יציבות תהליך המעקב.",
                        },
                        {
                            title: "מה המשמעות של השלטים ליד האנשים שאני עוקב אחריהם?",
                            content: "מעקב עובד בהצלחה.",
                            content2: "מחכה שהאדם שאתה עוקב אחריו ישלח לך הודעה.",
                            content3: "עליך לחבר את קוד ה-QR למערכת.",
                            content4: "מתכוננים לעקוב.",
                        }
                    ],
                    qr: {
                        shareButton: "לַחֲלוֹק",
                        waiting: "מחכה לחיבור",
                        countDownFirst: "שניות.",
                        countDownSecon: "קוד QR ירענן בעוד",
                        connected: "Connected",
                        opinion1: "פתח את WhatsApp בטלפון שלך",
                        opinion2: "הקש על הגדרות בחר התקנים מחוברים",
                        opinion3: "הקש על חיבור מכשיר",
                        opinion4: "סרוק את הקוד על ידי הפניית הטלפון שלך למסך זה",
                    },
                    share: {
                        title: "סרוק את הקוד על ידי הפניית הטלפון שלך למסך זה",
                        close: "Close",
                    },
                    link:"https://www.youtube.com/embed/DC53eDTk8fk?si=g48jUzmtplCV1fY2"
                },

            },
        }
    })
