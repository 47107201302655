import "./Card.css"
import {useTranslation} from "react-i18next";

const Card = (props) => {
    const { t, ready } = useTranslation();

    if (!ready) return 'loading translations...';

    return (
        <div className="card">
            <div className="flexx">
                <div className="sticker"></div>
                <p className="card-title">{t(`card.${props.index}.title`)}</p>
            </div>
            <p className="card-content">{props.index === 10 ?'✅ ':''}{t(`card.${props.index}.content`)}</p>
            {props.index === 10 ?
            <div>
                <p className="card-content">🕑 {props.index ? t(`card.${props.index}.content2`) : ''}</p>
                <p className="card-content">🔗 {props.index ? t(`card.${props.index}.content3`) : ''}</p>
                <p className="card-content">🔄 {props.index ? t(`card.${props.index}.content4`) : ''}</p>
            </div>
         : ''
}

        </div>
    )
}

export default Card
