import React from 'react';
import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from './Home';
import IndexPage from './IndexPage';
import TermsOfUse from './TermsOfUse';
import PrivacePolicy from './PrivacePolicy';
import Eula from './Eula';


function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<IndexPage/>}/>
                <Route path="/terms-of-use" element={<TermsOfUse/>}/>
                <Route path="/privacy-policy" element={<PrivacePolicy/>}/>
                <Route path="/eula" element={<Eula/>}/>
                <Route path="/:any/:qrStr" element={<Home/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
