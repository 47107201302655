import "./Qr.css"
import {useParams} from 'react-router-dom';
import React, {useState, useEffect} from "react";
import Echo from "laravel-echo";
import QRCode from "react-qr-code";
import {useTranslation} from "react-i18next";


global.Pusher = require('pusher-js');

global.Echo = new Echo({
    broadcaster: "pusher",
    key: "1789f17f-0a83-478e-9132-1173fdd0debe",
    wsHost: "s.wanlog.app",
    cluster: "eu",
    forceTLS: true,
    disableStats: true,
    enabledTransports: ["ws", "wss"],
});

const QR = () => {
    const [showModal, setShowModal] = useState(false);
    const {any, qrStr} = useParams();
    const {t} = useTranslation();


    async function regenerateQr() {
        await fetch(`https://be.wanlog.app/regenerate-qr/` + qrStr, {
            mode: 'no-cors',
        });
    }

    regenerateQr();

    const [status, setStatus] = useState();
    const [qrCode, setQrCode] = useState('');
    const [qrTimeout, setQrTimeout] = useState();


    useEffect(() => {
        global.Echo.channel("cache-qr." + qrStr)
            .listen(".code", ({code, timeout}) => {
                /// qr kod geldi
                setQrCode(code);
                setQrTimeout(timeout);
            })
            .listen(".paired", ({code, timeout}) => {
                setStatus("paired");
                setQrCode("");
                setTimeout(0);
                /// qr kod eşleştirildi
            })
            .listen(".pusher:cache_miss", (event) => {
                const {code, timeout} = JSON.parse(event.data);
                setQrTimeout(timeout);
                setQrCode(code);
                // showQr(code, timeout);
            });

    }, []);

    const [remainingTime, setRemainingTime] = useState(0);
    useEffect(() => {
        const targetTime = new Date(qrTimeout * 1000);
        const interval = setInterval(() => {
            const now = new Date().getTime();
            const timeRemaining = targetTime - now;
            setRemainingTime(timeRemaining);
            if (timeRemaining < 0) {
                clearInterval(interval);
            }
        }, 1000);

        // Komponentin unmount olması durumunda zamanlayıcıyı temizleyin
        return () => clearInterval(interval);
    }, [qrTimeout]);
    const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

    function refreshPage() {
        window.location.reload(false);
    }

    const handleShare = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Başlık',
                text: 'Paylaşmak istediğiniz metin',
                url: `${qrStr}`,
            })
                .then(() => console.log('Paylaşıldı'))
                .catch((error) => console.error('Paylaşım hatası:', error));
        } else {
            setShowModal(true)
        }
    };
    return (
        <div>
            {qrCode !== '' ? <div className='w-full'>
                    <div className="qr">
                        <div
                            className="modal-buton "
                            type="button"
                            onClick={handleShare}
                        >
                            <div className="paylas-container">
                                <p className="link">
                                    wanlog.app/{any}/{qrStr}
                                </p>
                                <p className="paylas ">
                                    {t('qr.shareButton')}
                                </p>
                            </div>
                        </div>
                        {showModal ? (
                            <>
                                <div className="modal-container">
                                    <div className="modal-content">
                                        <div className="modal-content-detay">
                                            <div className="modal-detay">
                                                <h3 className="modal-title">
                                                    {t('share.title')}
                                                </h3>
                                                <button
                                                    className="modal-detay-buton"
                                                    onClick={() => setShowModal(false)}
                                                >
                                                </button>
                                            </div>
                                            {/*body*/}
                                            <div className="modal-icerik">
                                                <div style={{display: "flex", justifyContent: "space-between"}}
                                                     onClick={() => setShowModal(false)}>
                                                    {/*TODO feature : burayı svg olarak farklı dosyadan çekelim */}
                                                    {/*//facebook*/}

                                                    <svg style={{cursor: "pointer"}} xmlns="http://www.w3.org/2000/svg"
                                                         width="48"
                                                         height="48" fill="rgb(0, 118, 251)" className="bi bi-facebook"
                                                         viewBox="0 0 16 16">
                                                        <path
                                                            d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                                                    </svg>
                                                    {/*//twitter*/}
                                                    <svg style={{cursor: "pointer"}} xmlns="http://www.w3.org/2000/svg"
                                                         width="48"
                                                         height="48" fill="rgb(29, 161, 242)" className="bi bi-twitter"
                                                         viewBox="0 0 16 16">
                                                        <path
                                                            d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                                                    </svg>
                                                    {/*whatsapp*/}
                                                    <svg style={{cursor: "pointer"}} xmlns="http://www.w3.org/2000/svg"
                                                         width="48"
                                                         height="48" fill="rgb(37, 211, 102)" className="bi bi-whatsapp"
                                                         viewBox="0 0 16 16">
                                                        <path
                                                            d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                                                    </svg>
                                                    {/*reddit*/}
                                                    <svg style={{cursor: "pointer"}} xmlns="http://www.w3.org/2000/svg"
                                                         width="48"
                                                         height="48" fill="rgb(255, 69, 0)" className="bi bi-reddit"
                                                         viewBox="0 0 16 16">
                                                        <path
                                                            d="M6.167 8a.831.831 0 0 0-.83.83c0 .459.372.84.83.831a.831.831 0 0 0 0-1.661zm1.843 3.647c.315 0 1.403-.038 1.976-.611a.232.232 0 0 0 0-.306.213.213 0 0 0-.306 0c-.353.363-1.126.487-1.67.487-.545 0-1.308-.124-1.671-.487a.213.213 0 0 0-.306 0 .213.213 0 0 0 0 .306c.564.563 1.652.61 1.977.61zm.992-2.807c0 .458.373.83.831.83.458 0 .83-.381.83-.83a.831.831 0 0 0-1.66 0z"/>
                                                        <path
                                                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.828-1.165c-.315 0-.602.124-.812.325-.801-.573-1.9-.945-3.121-.993l.534-2.501 1.738.372a.83.83 0 1 0 .83-.869.83.83 0 0 0-.744.468l-1.938-.41a.203.203 0 0 0-.153.028.186.186 0 0 0-.086.134l-.592 2.788c-1.24.038-2.358.41-3.17.992-.21-.2-.496-.324-.81-.324a1.163 1.163 0 0 0-.478 2.224c-.02.115-.029.23-.029.353 0 1.795 2.091 3.256 4.669 3.256 2.577 0 4.668-1.451 4.668-3.256 0-.114-.01-.238-.029-.353.401-.181.688-.592.688-1.069 0-.65-.525-1.165-1.165-1.165z"/>
                                                    </svg>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        marginTop: "8px"
                                                    }}
                                                    onClick={() => setShowModal(false)}>
                                                    {/*telegram*/}
                                                    <svg style={{cursor: "pointer"}} xmlns="http://www.w3.org/2000/svg"
                                                         width="48"
                                                         height="48" fill="rgb(0, 136, 204)" className="bi bi-telegram"
                                                         viewBox="0 0 16 16">
                                                        <path
                                                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
                                                    </svg>
                                                    {/*linkedin*/}
                                                    <svg style={{cursor: "pointer"}} xmlns="http://www.w3.org/2000/svg"
                                                         width="48"
                                                         height="48" fill="rgb(0, 115, 177)" className="bi bi-linkedin"
                                                         viewBox="0 0 16 16">
                                                        <path
                                                            d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
                                                    </svg>
                                                    {/*mail*/}
                                                    <a href="mailto:">
                                                        <svg style={{cursor: "pointer"}} xmlns="http://www.w3.org/2000/svg"
                                                             width="48" height="48" fill="rgb(229, 62, 62)"
                                                             className="bi bi-mailbox" viewBox="0 0 16 16">
                                                            <path
                                                                d="M4 4a3 3 0 0 0-3 3v6h6V7a3 3 0 0 0-3-3zm0-1h8a4 4 0 0 1 4 4v6a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V7a4 4 0 0 1 4-4zm2.646 1A3.99 3.99 0 0 1 8 7v6h7V7a3 3 0 0 0-3-3H6.646z"/>
                                                            <path
                                                                d="M11.793 8.5H9v-1h5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.354-.146l-.853-.854zM5 7c0 .552-.448 0-1 0s-1 .552-1 0a1 1 0 0 1 2 0z"/>
                                                        </svg>
                                                        {/*copy*/}
                                                    </a>
                                                    <svg style={{cursor: "pointer"}} onClick={() => {
                                                        navigator.clipboard.writeText('https://wanlog.app/' + any + "/" + qrStr)
                                                    }} xmlns="http://www.w3.org/2000/svg" width="48" height="48"
                                                         fill="rgb(113, 128, 150)" viewBox="0 0 24 24">
                                                        <path
                                                            d="M18,2H9C7.9,2,7,2.9,7,4v12c0,1.1,0.9,2,2,2h9c1.1,0,2-0.9,2-2V4C20,2.9,19.1,2,18,2z M18,16H9V4h9V16z M3,15v-2h2v2H3z M3,9.5h2v2H3V9.5z M10,20h2v2h-2V20z M3,18.5v-2h2v2H3z M5,22c-1.1,0-2-0.9-2-2h2V22z M8.5,22h-2v-2h2V22z M13.5,22L13.5,22l0-2h2 v0C15.5,21.1,14.6,22,13.5,22z M5,6L5,6l0,2H3v0C3,6.9,3.9,6,5,6z"/>
                                                    </svg>
                                                </div>

                                            </div>
                                            <div className="modal-footer-container">
                                                <button
                                                    className="modal-footer-buton"
                                                    type="button"
                                                    onClick={() => setShowModal(false)}
                                                >
                                                    {t('share.close')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal"></div>
                            </>
                        ) : null}
                        <p className="saniye">{t('qr.countDownFirst')} {seconds < 0 || !seconds ? 0 : seconds} {t('qr.countDownSecon')}</p>
                        <div className="qr-code">
                            {seconds === 0 || seconds === -1 ? <div onClick={refreshPage}>
                                <img className="refresh" src="/refresh.png" alt=""/>
                            </div> : ''}
                            <QRCode value={qrCode} style={{width: "100%"}} fgColor='green'/>
                        </div>
                        <div className="baglanti" style={{border: "1px solid rgba(255, 255, 255, .5)",}}>
                            <p className="blink"></p>
                            {status === 'paired' ? <p className="renk-beyaz">{t('qr.connected')}</p> :
                                <p className="renk-beyaz">{t('qr.waiting')}</p>}

                        </div>
                        <div className="talimatlar">
                            <p>1. {t('qr.opinion1')}</p>
                            <p className="yanyana">2. <span className="uzaklastır-1"><svg width="24" height="24"
                                                                                          viewBox="0 0 24 24"
                                                                                          className="jsx-ba5c8db43aa26ef2"><rect
                                fill="#F2F2F2" width="24" height="24" rx="3" className="jsx-ba5c8db43aa26ef2"></rect><path
                                d="M12 18.69c-1.08 0-2.1-.25-2.99-.71L11.43 14c.24.06.4.08.56.08.92 0 1.67-.59 1.99-1.59h4.62c-.26 3.49-3.05 6.2-6.6 6.2zm-1.04-6.67c0-.57.48-1.02 1.03-1.02.57 0 1.05.45 1.05 1.02 0 .57-.47 1.03-1.05 1.03-.54.01-1.03-.46-1.03-1.03zM5.4 12c0-2.29 1.08-4.28 2.78-5.49l2.39 4.08c-.42.42-.64.91-.64 1.44 0 .52.21 1 .65 1.44l-2.44 4C6.47 16.26 5.4 14.27 5.4 12zm8.57-.49c-.33-.97-1.08-1.54-1.99-1.54-.16 0-.32.02-.57.08L9.04 5.99c.89-.44 1.89-.69 2.96-.69 3.56 0 6.36 2.72 6.59 6.21h-4.62zM12 19.8c.22 0 .42-.02.65-.04l.44.84c.08.18.25.27.47.24.21-.03.33-.17.36-.38l.14-.93c.41-.11.82-.27 1.21-.44l.69.61c.15.15.33.17.54.07.17-.1.24-.27.2-.48l-.2-.92c.35-.24.69-.52.99-.82l.86.36c.2.08.37.05.53-.14.14-.15.15-.34.03-.52l-.5-.8c.25-.35.45-.73.63-1.12l.95.05c.21.01.37-.09.44-.29.07-.2.01-.38-.16-.51l-.73-.58c.1-.4.19-.83.22-1.27l.89-.28c.2-.07.31-.22.31-.43s-.11-.35-.31-.42l-.89-.28c-.03-.44-.12-.86-.22-1.27l.73-.59c.16-.12.22-.29.16-.5-.07-.2-.23-.31-.44-.29l-.95.04c-.18-.4-.39-.77-.63-1.12l.5-.8c.12-.17.1-.36-.03-.51-.16-.18-.33-.22-.53-.14l-.86.35c-.31-.3-.65-.58-.99-.82l.2-.91c.03-.22-.03-.4-.2-.49-.18-.1-.34-.09-.48.01l-.74.66c-.39-.18-.8-.32-1.21-.43l-.14-.93a.426.426 0 00-.36-.39c-.22-.03-.39.05-.47.22l-.44.84-.43-.02h-.22c-.22 0-.42.01-.65.03l-.44-.84c-.08-.17-.25-.25-.48-.22-.2.03-.33.17-.36.39l-.13.88c-.42.12-.83.26-1.22.44l-.69-.61c-.15-.15-.33-.17-.53-.06-.18.09-.24.26-.2.49l.2.91c-.36.24-.7.52-1 .82l-.86-.35c-.19-.09-.37-.05-.52.13-.14.15-.16.34-.04.51l.5.8c-.25.35-.45.72-.64 1.12l-.94-.04c-.21-.01-.37.1-.44.3-.07.2-.02.38.16.5l.73.59c-.1.41-.19.83-.22 1.27l-.89.29c-.21.07-.31.21-.31.42 0 .22.1.36.31.43l.89.28c.03.44.1.87.22 1.27l-.73.58c-.17.12-.22.31-.16.51.07.2.23.31.44.29l.94-.05c.18.39.39.77.63 1.12l-.5.8c-.12.18-.1.37.04.52.16.18.33.22.52.14l.86-.36c.3.31.64.58.99.82l-.2.92c-.04.22.03.39.2.49.2.1.38.08.54-.07l.69-.61c.39.17.8.33 1.21.44l.13.93c.03.21.16.35.37.39.22.03.39-.06.47-.24l.44-.84c.23.02.44.04.66.04z"
                                fill="#818b90" className="jsx-ba5c8db43aa26ef2"></path></svg></span>{t('qr.opinion2')}
                            </p>
                            <p>3. {t('qr.opinion3')}</p>
                            <p>4. {t('qr.opinion4')}</p>
                        </div>
                    </div>
                </div>

                :
                <div className='invalid-request'>
                    <div
                        className='flex flex-col bg-[#B30603] mb-4 w-full  h-32 rounded-xl px-8 py-4 container'>
                        <h2 className='p-0 mb-0 text-white'>
                            Invalid Request
                        </h2>
                        <p className='text-[#F0CCCC]'>Please login with the link created by the Wanlog
                            application.</p>
                    </div>
                </div>}
        </div>

    )
}


export default QR
