import Card from "./Card";
import QR from "./QR";
import Header from "./Header";
import {useTranslation} from "react-i18next";
const Home = () =>{
    const {t} = useTranslation();
    const cards = [
        {
            title:"Neden WhatsApp'ımı WanLog Uygulamasına Bağlamalıyım?",
            content:"Bu durumun can sıkıcı olduğunu biliyoruz fakat WhatsApp’ın son yapmış olduğu gizlilik güncellemesinden dolayı son 3 hafta içerisinde etkileşimde olmadığınız (mesajlaşma ve sesli görüşme) kişileri takip etmek artık mümkün değil. Dünyada ki hiçbir WhatsApp takip uygulaması artık çalışmadığını tekrar hatırlatmak isteriz. Fakat QR kodunuzu sistemimize bağlayarak daha önce sohbet ettiğiniz ya da sesli görüşme yaptığınız kişilerin takipleri aktif bir şekilde devam edecektir."
        },
        {
            title:"WhatsApp'ımı Sisteme Nasıl Bağlayabilirim?",
            content:"Ekranda belirttiğimiz QR kodunu WhatsApp uygulamasına girip 'cihazınızı bağlayın' seçeneğine tıklayarak okutmanız gerekmektedir.\n" +
                "Bu işlemi yapmanız için 2 adet cihaza ihtiyacınız olacaktır. Bir cihazdan QR kodunu görüntüleyip kendi telefonunuzdan QR kodunu okutmanız gerekmektedir. Sayfanın en üstünde bulunan video da gerekli tüm aşamaları ve talimatları görebilirsiniz."
        },
        {
            title:"WhatsApp'ımı Sisteme Bağlamak İstemiyorum!",
            content:"Endişenizi anlıyoruz, Fakat Takip işleminin başlaması için bu işlemi yapmanız gerekmektedir. WhatsApp Bağlantınızı yapmazsanız takip işlemi sadece WhatsApp Business numaralar için başlayacaktır"
        },
        {
            title:"QR Kodunu Sisteme Bağladığımda Gizliliğim Ne Olacak?",
            content:"QR kodunu sisteme bağlama işleminde sadece takibi başlatmak için gereken verileri bilgisini alacağız. Mesajlarınız, medyalarınız ve sohbet geçmişiniz hiçbir şekilde sunucularımızda depolanmayacaktır."
        },
        {
            title:"QR Kodunu Okuttuğum Zaman Sürekli “Çevrimiçi” Olarak Mı Görüneceğim?",
            content:"Kesinlikle hayır, QR kodu okutma işleminde gereken veri alındıktan sonra oturumunuz bizim tarafımızdan “çevrimdışı” durumuna alınacaktır."
        },
        {
            title:"Takip Ettiğim Kişinin De QR Kodunu Sisteme Bağlaması Gerekiyor Mu?",
            content:"Hayır, sadece sizin QR kodunuzu sisteme bağlamanız yeterlidir. Takip ettiğiniz numaranın hiçbir işlem yapmasına gerek yoktur."
        },
        {
            title:"Takip Edeceğim Kişinin Çevrimiçi Bilgisi Kapalı, Yine De Takip Edebilir Miyim?",
            content:"Evet, takip ettiğiniz kişinin son görülmesi ya da çevrimiçi durumundan bağımsız bir şekilde takip işleminiz sorunsuz şekilde yapılacaktır."
        },
        {
            title:"Takip Ettiğim Kişi Takip Edildiğini Görebilecek Mi?",
            content:"Hayır, takip işlemlerinden tamamen anonimsiniz. Takip ettiğiniz numara takip edildiğini hiçbir koşulda anlamayacaktır."
        },
        {
            title:"Telefon Numarasını Engellediğim Yada Engellendiğim Kişiyi Takip Edebilir Miyim?",
            content:"Teknik ekibimiz bu konu ile alakalı çalışmalarını devam ettirmektedir. En kısa süre içerisinde engellediğiniz yada engellendiğiniz numaralarında takip edilmesi içinde güncelleme yayınlayacağız."
        },
        {
            title:"WhatsApp'ım Sürekli WanLog Sistemine Bağlı Kalmak Zorunda Mı?",
            content:"Hayır, WhatsApp uygulamanızı sisteme bağladıktan sonra bağlantıyı kesebilirsiniz. Fakat takip işleminin stabil devam etmesi için bağlantıdan çıkış yapmamanızı öneriyoruz."
        },
        {
            title:"Takip Ettiğim Kişilerin Yanında Ki İşaretler Ne Anlama Geliyor?",
            content:"✅ Takip başarıyla çalışıyor.",
            content2:"🕑 Takip ettiğiniz kişinin size mesaj atmasını bekleniyor.",
            content3:"🔗 QR kodunu sisteme bağlamanız gerekiyor.",
            content4:"🔄 Takip etmeye hazırlanıyor.",
        },
    ]
    return(
        <div>
            <Header/>
            <div className="video-container" style={{marginBottom:"16px"}} >
                <div className="flex-dik">
                    <iframe src={t('link')} className="iframee"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>

                    {
                        cards.map((card, index) => {
                                return <Card key={index} index={index} title={card.title} content={card.content} content2={card.content2} content3={card.content3} content4={card.content4}/>
                            }
                        )
                    }
                </div>
                <QR/>
            </div>
        </div>
    )
}

export default Home
