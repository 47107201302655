import React from 'react';
import Swal from 'sweetalert2'
import {Link} from 'react-router-dom'
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import {Autoplay} from 'swiper/modules';
import bg from '../src/assets/image/bg.png'
import './dist/output.css'

const IndexPage = () => {
    function modal() {

        const ipAPI = '+15 000000000000'
        const {value: tel} = Swal.fire({
            color: '#21b5e2',
            title: 'Unlist',
            input: 'number',
            inputLabel: 'Enter your number to stop the tracking process.',
            inputPlaceholder: ipAPI,
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return 'Bir şeyler yazman gerkeli'
                }
            }
        })

        if (tel) {
            Swal.fire(`Your IP address is ${tel}`)
        }
    }


    return (
        <div
            className="flex flex-col bg-[#0F1115] min-h-screen   bg-contain bg-center bg-no-repeat  bg-fixed  transition-all duration-500"
            style={{backgroundImage: `url(${bg})`, backgroundColor: 'rgba(0,0,0,0.5)'}}>
            <div className="md:px-12 pt-8 px-4 flex justify-between  items-center">
                <Link to={'/'}>
                    <img src={require('./assets/image/logo.png')}
                         className=" w-24 md:w-32  transition-all duration-300 "
                         alt=""/>
                </Link>
                <span className="relative inline-block text-lg group cursor-pointer" style={{textDecoration: 'none'}}
                      onClick={() => {
                          modal()
                      }}>
<span
    className="relative z-10 block md:px-5 px-3 md:py-3 py-1 overflow-hidden font-medium leading-tight text-white transition-colors duration-300 ease-out rounded-lg group-hover:text-[#21b5e2]">
<span
    className="absolute inset-0 w-full h-full md:px-5 px-3 md:py-3 py-1 rounded-lg bg-[#21b5e2] transition-all duration-300"></span>
<span
    className="absolute left-0 md:w-48 w-32 md:h-48 h-32 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-white group-hover:-rotate-180 ease"></span>
<span className="relative">Unlist</span>
</span>
      <span
          className="absolute bottom-0 right-0 w-full md:h-12 h-8 md:-mb-1 -mb-0.5 md:-mr-1 -mr-0.5 transition-all duration-200 ease-linear bg-white rounded-lg group-hover:mb-0 group-hover:mr-0"
          data-rounded="rounded-lg"></span>
    </span>
            </div>
            <div className="w-[265px] h-[539px] relative mx-auto mt-14">
                <div className="w-full h-full relative pointer-events-none flex z-50 overflow-hidden">
                    <img src={require('./assets/image/phone.png')} className={"w-full"} alt=""/>
                </div>
                <div className="flex items-center absolute overflow-hidden top-2 left-3 right-3 bottom-3">
                    <Swiper autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                            modules={[Autoplay]}
                            spaceBetween={15}
                            loop={true} className="mySwiper rounded-2xl">
                        <SwiperSlide><img
                            src={require('./assets/image/p2.png')}
                            alt=""/></SwiperSlide>
                        <SwiperSlide><img
                            src={require('./assets/image/p1.png')}
                            alt=""/></SwiperSlide>
                        <SwiperSlide><img
                            src={require('./assets/image/p3.png')}
                            alt=""/></SwiperSlide>
                        <SwiperSlide><img
                            src={require('./assets/image/p.png')}
                            alt=""/></SwiperSlide>
                        <SwiperSlide><img
                            src={require('./assets/image/p4.png')}
                            alt=""/></SwiperSlide>
                        <SwiperSlide><img
                            src={require('./assets/image/p5.png')}
                            alt=""/></SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div
                className="mt-auto flex justify-center flex-col md:flex-row md:justify-between  px-6 md:px-12  pb-4 md:pb-12 items-center ">
                <a href="https://apps.apple.com/us/app/wanlog-online-tracker/id6446702650" target="_blank"
                   className="flex md:hidden text-white mt-4" style={{textDecoration: 'none'}} rel="noreferrer">  Apple
                    Store</a>
                <div>
                    <div className="flex gap-3 text-md mt-4  justify-center md:justify-start">
                        <Link to={'/privacy-policy'}
                              className="text-white hover:text-[#21b5e2] transition-all duration-200 "
                              style={{textDecoration: 'none'}}>Privacy Policy</Link>
                        <Link to={'/terms-of-use'}
                              className="text-white hover:text-[#21b5e2]  transition-all duration-200 "
                              style={{textDecoration: 'none'}}>Terms Of Use</Link>
                        <Link Link to={'/eula'}
                              className="text-white hover:text-[#21b5e2]  transition-all duration-200 "
                              style={{textDecoration: 'none'}}>E.U.L.A</Link>
                    </div>
                    <div className="text-gray-600 mt-2">Copyright © 2023 WanLog. All Rights Reserved.</div>
                </div>

                <div>
                    <a className="hidden md:block"
                       href="https://apps.apple.com/us/app/wanlog-online-tracker/id6446702650"
                       target="_blank" rel="noreferrer">
                        <img
                            src={require('./assets/image/apple.png')}
                            className=" rounded-xl hover:scale-110 transition duration-500 w-[118px] h-[42.28] cursor-pointer "
                            alt=""
                            style={{border: '2px solid white '}}/>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default IndexPage
