import "./Header.css";
import {Link} from 'react-router-dom'
import HeaderDropDown from "./HeaderDropDown";

const Header = () => {
    return (
        <div className="header">
            <div style={{display: "flex", alignItems: "center"}}>
                <Link to="/">
                    <img style={{width: "48px", height: "48px"}} src="/LOGO.png" alt=""/>
                    <img style={{marginLeft: "8px"}} src="assets/image/logo.png" alt=""/>
                </Link>
            </div>
            <HeaderDropDown/>
        </div>
    );
}

export default Header
